import { useReducer } from "react"
import PropTypes from "prop-types";


const useSearchState = (defaultSites) => {
    
    const [{sites, searchPref, query}, dispatch] = useReducer((state, action) => {
            switch (action.type) {
                case 'SET_SITES':
                    return {
                        ...state,
                        sites: action.sites
                    }
                case 'SET_SEARCH_PREF':
                    return {
                        ...state,
                        searchPref: action.searchPref
                    }
                case 'SET_QUERY':
                    return {
                        ...state,
                        query: action.query
                    }
                case 'SET_QUERY_FROM':
                    return {
                        ...state,
                        query: {query: state.query.query, from: action.from}
                    }
                default:
                    throw new Error()
            }
        }, 
        {
            sites: defaultSites,
            searchPref: 'membership_content',
            query: {query:'',type:'default'}
        }
    )

    const setSites = (sites) => {
        dispatch({type: 'SET_SITES', sites: sites})
    }

    const setSearchPref = (searchPref) => {
        dispatch({type: 'SET_SEARCH_PREF', searchPref: searchPref})
        dispatch({type: 'SET_QUERY_FROM', from: 'searchPref'})
    }
    const setQuery = (query) => {
        dispatch({type: 'SET_QUERY', query: query})
    }

    return [sites, setSites, searchPref, setSearchPref, query, setQuery]
}

useSearchState.propTypes = {
    sites: PropTypes.string,
    setSites: PropTypes.func,
    searchPref: PropTypes.string,
    setSearchPref: PropTypes.func,
    query: PropTypes.object,
    setQuery: PropTypes.func
}

export default useSearchState    
import React from "react"
import FitText from '../Common/FitText'
// import clamp from '../Utils/clamp';

const CoursePosterHit = ({ item, name, height, charcount, logOutbound }) => {
  const title = item?._source?.post_title || ''
  const noPoster = (item?._source?.thumbnail || '').split('?')[1] === 'no-poster'
  // TODO - passa in detta från settings tänker jag
  // const baseurl = `${sites[item._source.site]}`
  // const url = baseurl !== 'undefined' ? `https://${baseurl}${item._source.url}` : `${item._source.url}`
  const url = `https://play.moderskeppet.se${item._source.url}`
  const maxchars = !!charcount ? charcount : 120
  const desc = item._source.description.split('').length < maxchars ? item._source.description : item._source.description.slice(0, maxchars) + '…'
  const aspectRatio = 118/163;
  return (
    <>
    <div onClick={(evt) => logOutbound(url, evt)} className={`course-hit`}>
      <style jsx>{`
          .course-hit {
            display: grid;
            img, .description, .poster-title {
              grid-row: 1;
              grid-column: 1;
            } 
            .poster-title {
              align-self: end;
              font-size: 12px;
              width: 100%;
            }

            img {
              object-fit: cover;
              object-position: center;
              width: 100%;
              //max-height: 270px;
              border-radius: 4px;
              aspect-ratio: 23/32;

            } .description {
              display: none;
            }

          @media (min-width: 768px) {
            &:hover .description {
              opacity:1;
            }
            .description {
              display:block;
              transition: opacity 0.2s;
              opacity:0;
              cursor: pointer;
              color: #fff;
              font-size: 0.9em;
              margin-bottom: 0;
              padding: 0.5em;
              text-align: left;
              width: 100%;
              overflow:hidden;
              background-color: rgba(0,0,0,0.7);
              background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0) 90%);
              border-radius: 4px;

            .truncate-overflow {
              margin:0;
              display: -webkit-box;
              -webkit-line-clamp: 7;
              line-clamp: 7;
              -webkit-box-orient: vertical;
              box-orient: vertical;
              max-height: calc(${aspectRatio * height}px + 0.5em);
              overflow: hidden;
            }
            }

          }
          }
        `}</style>
      <img src={getImageUrl(item._source.site, item._source.doctype, item._source.thumbnail)} alt="" title={title} />
      {title !== '' && noPoster && <div className="poster-title"><FitText courseTitle={title} /></div>}
      <div className="description "><p className="truncate-overflow">{desc}</p></div>
    </div>
    </>
  )
}
// const sites = {
//   '': 'moderskeppet.se',
//   'ms': 'moderskeppet.se',
//   'some': 'moderskeppet.se'
// }

const getImageUrl = (site, type, thumb_url) => {
  if (thumb_url.includes('http'))
    return thumb_url

  return `https://moderskeppet.se/${thumb_url}`
}
// clamp(myParagraph, {clamp: 'auto'})
export default CoursePosterHit
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Search from './Components/Search/Search'
import Standalone from './Components/Search/Standalone'
import { SearchProvider } from './Providers/SearchProvider.js'
import _JSXStyle from 'styled-jsx/style';
 
if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle })
}


let elem = document.getElementById('search-input')

const api_url = process.env.NODE_ENV === 'development' ? 'https://moderskeppet.lvh.me/wp-json' : 'https://moderskeppet.se/wp-json'

const settings = elem && ('settings' in elem.dataset) ? JSON.parse(elem.dataset.settings) : {
  sites:'ms,foto,video,design,some,m365,verktyg,me,forfattare,akademi',
  API_URL: api_url,
  site_name: 'standalone'
}

if(elem) {
  ReactDOM.render(
    <React.StrictMode>
      <SearchProvider {...settings}>
        <Search />
      </SearchProvider>
    </React.StrictMode>,
    elem
  );  
} else {
  elem = document.getElementById('root')

  ReactDOM.render(
    <React.StrictMode>
      <SearchProvider sites={settings.sites} API_URL={settings.API_URL}>
      <Standalone>
        <Search mode="standalone" />
      </Standalone>
      </SearchProvider>
    </React.StrictMode>,
    elem
  )  
}
import React, { useContext, useEffect, useCallback, useRef } from 'react'
import DocTypeHits from './DocTypeHits'
import SearchPreferences from './SearchPreferences'
import { SearchContext } from '../../Providers/SearchProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import space from '../../images/space.svg'
import astronaut from '../../images/astronaut.svg'
import { useMemo } from 'react'

const SearchResults = ({ debug }) => {
    let {
        query,
        setQuery,
        course_hits,
        totalHits,
        course_count,
        document_hits,
        document_count,
        movie_hits,
        movie_count,
        pinned,
        isLoading,
        mode,
        page,
        dispatch,
        reset,
        searchPref,
        setSearchPref,
        loggedin,
        premium,
        active_membership,
        site_name,
        PUBLIC_URL
        // version
    } = useContext(SearchContext)

    if (!debug) {
        debug = false
    }

    const loadSpinner = useRef(null)
    const hasPinned = (typeof pinned === 'object' && pinned.length > 0) || (typeof pinned === 'boolean' && pinned === true)

    // anledningar till att inte visa prefs:
    const noSearchPrefs = useMemo(() => {
        const www = site_name === 'Moderskeppet'
        const loggedInNoAccessWww = loggedin && !active_membership && www
        const visitorAtWww = !loggedin && www
        return loggedInNoAccessWww || visitorAtWww || premium
    }, [loggedin, active_membership, site_name, premium])

    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                reset()
            }
        },
        [reset]
    )

    const setDetail = (name) => {
        setQuery({ query: query.query, from: 'mode' })
        dispatch({ type: 'SET_MODE', mode: name })
    }

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [escFunction])

    return (
        <div className="searchresults">
            <style global jsx>{`
                .notfound {
                    padding: 10px;
                    width: 100%;
                    background-image: url(${`${PUBLIC_URL}${space}`});
                    text-align: right;
                    overflow: hidden;

                    p:first-child {
                        font-size: 20px;
                        margin-bottom: 5px;

                        em {
                            font-style: normal;
                            color: #ff9800;
                            font-weight: 700;
                        }
                    }

                    p,
                    a {
                        text-align: center;
                        color: #fff;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    img {
                        margin-top: 10px;
                        max-width: 240px;
                        position: relative;
                        bottom: -10px;
                        right: -10px;
                    }
                }
                @media (max-width: 767px) {
                    div.searchresults {
                        top: 60px !important;
                    }

                    div.doctype-header {
                        margin: 0 !important;
                        padding: 0 !important;
                        margin-left: 8px !important;
                    }

                    div.doctype-hits {
                        margin: 5px !important;
                        padding: 0 !important;
                        h2 {
                            font-size: 90% !important;
                        }
                    }

                    div.img {
                        /*display: none;*/
                    }

                    div.fadeout {
                        display: none;
                    }

                    div.course-hit,
                    div.doc-hit,
                    div.movie-hit {
                        height: auto !important;
                        margin: 8px 5px !important;
                        padding: 3px !important;
                        font-size: 16px !important;
                    }

                    div.pinned-hit {
                        height: auto !important;
                        margin: 8px 5px !important;
                        padding: 3px;
                        font-size: 18px !important;

                        .description {
                            @media (max-width: 480px) {
                                display: none;
                            }
                        }

                        div.img {
                            display: block;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            `}</style>

            <style jsx>{`
                .searchresults {
                    position: 'unset';
                    background-color: white;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    top: 60px;
                    /* padding: 10px 10px 10px 0; */

                    @media (max-width: 992px) {
                        top: 120px;
                        padding: 0;

                        .reset {
                            display: none;
                        }
                    }

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: ${mode !== 'default' ? 'center' : 'left'};
                    align-items: stretch;
                    align-content: flex-start;

                    overflow-y: auto;
                    font-size: 14px;
                    line-height: 18px;
                }

                .reset {
                    position: absolute;
                    right: 0;
                    padding: 5px 11%;
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                .loading {
                    background-color: #ccc;
                    opacity: 0.8;
                    position: fixed;
                    padding: 20px;
                    z-index: 1;
                    left: ${`calc(50% - ${(loadSpinner.current?.offsetWidth || 120) / 2}px)`};
                    span {
                        animation: spin 1s linear infinite;
                        display: inline-block;
                        transform-origin: center;
                    }
                    border: #ccc solid;
                    border-radius: 5px;
                }
                @keyframes spin {
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
            `}</style>

            {isLoading && (
                <div ref={loadSpinner} className="loading">
                    Söker…{' '}
                    <span>
                        <FontAwesomeIcon icon={faSpinner} />
                    </span>
                </div>
            )}
            {/* <div className="reset"><FontAwesomeIcon className="resetbtn" onClick={reset} icon={faWindowClose}/></div> */}

            {noSearchPrefs ? null : (
                <SearchPreferences site_name={site_name} loggedin={loggedin} active_membership={active_membership} searchPref={searchPref} setSearchPref={setSearchPref} />
            )}

            {totalHits === 0 && !isLoading && (
                <div className="notfound">
                    <p>
                        Vi kunde tyvärr inte hitta något som matchar <em>{query.query}</em>
                    </p>
                    {!!loggedin && !premium && searchPref === 'membership_content' && (
                        <p>
                            <a
                                title="Sök även på innehåll du ännu inte har tillgång till i ditt medlemskap"
                                href="#0"
                                onClick={() => {
                                    setSearchPref('all')
                                }}
                            >
                                Prova att söka på hela Moderskeppet
                            </a>
                        </p>
                    )}
                    <img src={`${PUBLIC_URL}${astronaut}`} alt="Astronaut som söker i teleskop" />
                </div>
            )}
            {
                // pinned visas bara om det är mode default och det finns pinned att visa
                hasPinned && mode === 'default' && <DocTypeHits pinned name="pinned" hits={pinned} />
            }
            {/* <div>Sites:{sites}, searchPref:{searchPref} query: {JSON.stringify(query)}</div> */}
            <DocTypeHits
                caption="Kurser"
                name="course"
                count={course_count}
                hits={course_hits}
                mode={mode}
                page={page}
                dispatch={dispatch}
                setDetail={setDetail}
                debug={debug}
            />
            <DocTypeHits
                caption="Avsnitt"
                name="movie"
                count={movie_count}
                hits={movie_hits}
                mode={mode}
                page={page}
                dispatch={dispatch}
                setDetail={setDetail}
                debug={debug}
            />
            <DocTypeHits
                caption="Sidor"
                name="doc"
                count={document_count}
                hits={document_hits}
                mode={mode}
                page={page}
                dispatch={dispatch}
                setDetail={setDetail}
                debug={debug}
            />
        </div>
    )
}
export default SearchResults

import React from "react"

import DocHit from './DocHit'
import CoursePosterHit from './CoursePosterHit'
 
import MovieHit from './MovieHit'
import DocTypeHeader from "./DocTypeHeader"
import PageNav from './PageNavigator'
import ShowSubPageNav from "./ShowSubPageNav"
import CourseHit from "./CourseHit"

const DocTypeHits = ({caption, hits, count, name, pinned, mode, page, dispatch, setDetail, debug }) => {
    if(count === 0) return null
    const showSubpage = ( (mode !== name) && (count > 5) )

    const height='100'
    const posterHeight='163'

    const logOutbound = (url) => {
      //console.log('logOutbound', url)
      window.location = url
    }
    return (
        <div className={`${name} ${pinned ? '' : 'doctype-hits'}`}>
         
          <style jsx>{`
            .pinned ul {
              max-width: 640px;
              margin: 0 auto;

              @media (max-width: 767px) {
                max-width:100%;
              }
              li {
                @media (min-width: 1024px) {
                  flex: 1;
                }                

              }
            }
            ul {
              display:flex;
              flex-direction: column;
              padding: 0;
              margin: 0;

              li {
                list-style-type: none;
                flex: 0;
              }
            }

            .score {
              position: absolute;
              top: 0;
              right: 0;
              border: 1px solid grey;
              border-radius: 0 0 0px 5px;
              background-color: #ccc;
              opacity: 0.5;
              font-size: 80%;
              padding: 2px;
            }

            .result-item {
              position: relative;
            }

            .pinned {
              width: 100%;
              height: auto;
              padding: 1rem 1rem 0;
              @media (max-width:767px) {
                padding: 10px 10px 0;
              }
            }
            .doctype-hits {
              max-width: 680px;
              min-width: 270px;
              padding: 0 20px;
              flex: 1 1 220px; 
              &.course {
                ${ mode === 'default' && `max-width: 100%;`}
                flex: 0 1 100%;
                
                .carousel {
                  width: 100%;
                  overflow-x: scroll;
                  
                  @media (min-width: 768px){
                    overflow: auto;
                  }

                  ul.default-course {
                    display: grid;
                    justify-items: center;
                    grid-template-columns: repeat(6,1fr);
                    width: 175%;
                  }
                }
              }
            }

            @media (min-width: 768px) {    
              .doctype-hits {
                /* flex: 1 1 220px;  */
                &.course {
                  ul.default-course {
                    justify-content: start;
                    grid-template-columns: repeat(${count<=5 ? count : 5}, minmax(min-content, 215px)) ${count>5 ? '0.5fr !important' : ''};
                    grid-gap: 1rem;
                    width: 100% !important;
                  }
                }
              }
            }   
          `}</style>
          <style jsx global>{`
            @media (max-width: 1420px) {    
            .doc-hit,.movie-hit,.course-hit {
              font-size: 95% !important;
              >.img {
                  /* width: 60px !important; */
               }
            }
          }   
          `}</style>
        {!pinned && <DocTypeHeader caption={caption} name={name} count={count} page={page} mode={mode} dispatch={dispatch} logOutbound={logOutbound} />}
       <div className={name === 'course' && mode === 'default' ? 'carousel' : ''}>
          <ul className={`${mode}-${name}`}>
            {hits.map(item => (
                <li key={item._id}>
                  <div className="result-item">
                  {!!debug && <div className="score">{item._score} | {item._source.site}</div>}
                {name==='pinned' && <DocHit pinned={true} charcount={500} item={item} name={name} height={height} logOutbound={logOutbound} />}
                {name==='doc' && <DocHit item={item} name={name} height={height} logOutbound={logOutbound} />}
                {
                  // Show posters on first result, hits on sub pages
                  name==='course' && <>
                  {mode === name && <CourseHit item={item} name={name} height={height} logOutbound={logOutbound} /> }
                  {mode !== name && <CoursePosterHit item={item} name={name} height={posterHeight} logOutbound={logOutbound} /> }
                 </>
                 }
                {name==='movie' && <MovieHit item={item} name={name} height={height} logOutbound={logOutbound} />}
                </div>
              </li>
            ))}
              {showSubpage && <ShowSubPageNav name={name} caption={caption} count={count} setDetail={setDetail}></ShowSubPageNav> }
          </ul>
        </div>
        {mode===name && <PageNav count={count} page={page} dispatch={dispatch} />}
    </div>
  )
}

export default DocTypeHits 

import React from "react"

const MovieHit = ({item, name, height, charcount, logOutbound}) => {

    if( item._source.site.includes('ms '))
      item._source.site = 'ms'


    const url = `https://play.moderskeppet.se${name==='movie' ? '/' : ''}${item._source.url}`

    const maxchars = !!charcount ? charcount : 90
    const desc = item._source.description_full?.split('')?.length || 0 < maxchars ? item._source.description_full || '' : item._source.description_full.slice(0,maxchars) + '…'
    const mobileHeight = height*1.5;

    return  (
        <div onClick={(evt)=>logOutbound(url,evt)} className={`movie-hit`}>
            <style jsx>{`
            .movie-hit {
              // width: calc(100% - 10px);
              clear: both;
              margin: 15px;
              margin-left: 0;
              font-size: 1.1em;
              height: 100px;
              overflow: hidden;
              // border-bottom: none !important;
              cursor: pointer;
              position: relative;
              // box-shadow: 0 0 0px 1px rgba(0,0,0,0.1), 0 .125rem .25rem rgba(0,0,0,.15);
              border-bottom: dashed 1px rgba(0,0,0,0.1);
              //border-radius: 4px;
              padding: 0 !important;
              box-sizing: border-box; 

              @media (min-width: 768px) {
                border-bottom: none !important;
              }
            }
            .img {
                display: none;
                background-image: url(${`https://${getImageUrl(item._source.site, item._source.doctype,item._source.thumbnail)}`}) !important;
                background-color: #eee;
                background-size: cover;
                background-position: center top;
                width: 100%; 
                height: ${mobileHeight}px;

                @media (min-width: 768px) {
                  display: inline-block;
                  background-color: #eee;
                  background-size: cover;
                  background-position: center center;
                  height: ${height}px;
                  float: left;
                  margin: 10px;
                  margin-top: 0;
                  border: 1px solid #ccc;
                  border-radius: 4px;            
                  width: 120px;
                }
              }
            .description-container {
              padding: 0;
              margin: 0.2em;
            }
            .coursename {
              font-size:0.9em;
              margin-bottom: 0;
            }
            .description {
              color: #888;
              font-size: 0.8em;

              @media (min-width: 768px) {
                font-size: 0.9em;
              }

            &.truncate-overflow {
              margin:0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (min-width: 768px) {
                white-space: normal;
                display: -webkit-box;       
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                box-orient: vertical;
              }
            }

            }

        `}</style>
              <div className="img"></div>
              <div className="description-container">
                <a href={url} onClick={(evt)=>logOutbound(url,evt)}>{item._source.post_title}</a>
                <p className="coursename">{item._source.description}</p>
                <div className="description truncate-overflow">{desc}</div>
              </div>

        </div>
      )
    } 

  const getImageUrl = (site, type, thumb_url) => {
        return `cdnguld.moderskeppet.se${thumb_url}`
  }

  export default MovieHit
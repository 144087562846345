import React, { useContext } from 'react'

import { SearchContext } from '../../Providers/SearchProvider'
import version from '../../Utils/version'


const Standalone = ({children}) => {

    const { sites, setSites, defaultSites } = useContext(SearchContext)

    const changeSites = (site, from) => {
        
        const newSites = defaultSites.split(',').map( s => {
            if( s === site ) {
                if(from) {
                    return false
                }
                else return s
            }
            if( sites.includes(s))
                return s
            return false
        }).join(',')
        setSites(newSites)
    }

    const siteCheckBoxes = defaultSites.split(',').map( site => {
        const checked = sites.includes(site)
        return <span key={site}><input type="checkbox" key={site} onChange={(e) => changeSites(site, checked)} checked={checked} value={site}/> {site} </span>
    })


    return (
        <div className="standalone">
            <style jsx>{` 
                .standalone {
                    text-align: center;
                    color: white;
                }
                .siteboxes {
                    margin: 10px;
                }
            `}</style>
            <div className="version-info">Moderskeppet Sök {version}</div>
            <div className="siteboxes">{siteCheckBoxes}</div>
            {children}
        </div>
    )
}

export default Standalone
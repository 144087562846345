import React from "react"

function FitText({courseTitle}) {
    const text = subTitle(courseTitle)
    return (
        <svg viewBox="0 0 85 60">
            <style jsx>
                {` 
                svg {
                    width: 100%;

                    foreignObject {
                        position: relative;
                        p {
                            font-weight: 900;
                            text-align: left;
                            hyphens: auto;
                            color: #fff;
                            line-height:1.1;
                            padding:0 5px;
                            margin-bottom: 0;
                            text-shadow: 0.5px 0.5px #444;
                            text-overflow: ellipsis;
                            position: fixed;
                            bottom: 0;
                        }
                        .small-text {
                            font-size: 0.6em;
                        }
                        .svg-text {
                            fill: #fff;
                            font-size: 0.6em;
                        }
                    }
                }
                `}
            </style>
            <switch>
                <foreignObject x="0" y="0" width="100%" height="60">
                <p lang="de" xmlns="http://www.w3.org/1999/xhtml">
            {text.subtitle ?  <>{text.title}<br/><span className="small-text">{text.subtitle}</span></> : text.title}
                </p>
                </foreignObject>
                <text x="0" y="40" fill="#fff">{text.title}</text>
            </switch>
        </svg>    
        )
}

function subTitle(text) {
    if (text) {
        const emdash = text.split(' – ')
        const dash = text.split(' - ')
        if (emdash.length > 1) {
            return {title: emdash[0], subtitle: emdash[1]}
        }
        if (dash.length > 1) {
            return {title: dash[0], subtitle: dash[1]}
        }        
    }
    return {title: text, subtitle: null}
}
export default FitText
import React from 'react'
import RadioOption from '../Common/RadioOption'

function SearchPreferences({ loggedin, searchPref, setSearchPref, site_name, active_membership }) {
    const radioButtons = loggedin && active_membership
        ? [
            {
                selected: searchPref === 'membership_content',
                description: `Sök innehåll jag har tillgång till i mitt medlemskap`,
                value: 'membership_content',
                onChange: setSearchPref
            },
            {
                selected: searchPref === 'all',
                description: 'Sök på hela Moderskeppet',
                value: 'all',
                onChange: setSearchPref
            }
        ]
        : [
            {
                selected: searchPref === 'membership_content',
                description: `Sök på ${site_name}`,
                value: 'membership_content',
                onChange: setSearchPref
            },
            {
                selected: searchPref === 'all',
                description: 'Sök på hela Moderskeppet',
                value: 'all',
                onChange: setSearchPref
            }
        ]

    return (
        <div className={`search-preferences`}>
            {/* <span className="search-settings-btn" onClick={()=>{setShowSearchPrefsPane(!showSearchPrefsPane)}}>
              {<FontAwesomeIcon icon={faEllipsisH} size="1x"/>}
            </span> */}
            <style global jsx>
                {`
                    .search-preferences {
                        width: 100%;
                        padding: 10px 30px;
                        background-color: #0f1114;
                        color: #d0d4dc;
                        border-left: 2px solid #343a46;
                        border-right: 2px solid #343a46;

                        @media (max-width: 767px) {
                            padding: 15px;
                        }
                        .search-settings-btn {
                            display: none;
                            @media (max-width: 768px) {
                                display: table-cell;
                            }
                        }

                        .hide-search-form {
                            display: none;

                            @media (min-width: 768px) {
                                display: block;
                            }
                        }

                        fieldset {
                            display: flex;
                            flex-direction: row;

                            @media (max-width: 768px) {
                                flex-direction: column;
                            }

                            legend {
                                display: none;
                            }

                            .radio-option {
                                display: flex;
                                align-items: center;
                                font-size: 1.2em;
                                padding: 7px 0;

                                &:nth-child(2) {
                                    /* fieldset räknas som första elementet */
                                    margin-right: 30px;
                                }

                                @media (max-width: 480px) {
                                    font-size: 1em;
                                    margin-left: 0px;
                                    align-items: baseline;
                                }
                                .hide-radio {
                                    display: none;
                                }
                                .radio-option-input {
                                    margin-top: 0;
                                }

                                .radio-option-description {
                                    margin-left: 10px;
                                    font-weight: 400;
                                    margin-bottom: 0;
                                    @media (max-width: 767px) {
                                        font-size: 1em;
                                    }
                                }
                            }
                        }
                    }
                `}
            </style>
            <form action="">
                <fieldset>
                    <legend>Sökinställningar</legend>
                    {radioButtons.map(
                        (
                            { selected, description, value, onChange }
                        ) => (
                            <RadioOption color="green" bgColor="#fff" key={value} selected={selected} description={description} value={value} onChange={onChange} />
                        )
                    )}
                </fieldset>
            </form>
        </div>
    )
}
export default SearchPreferences

import React, {useContext} from "react"
import { SearchContext } from '../../Providers/SearchProvider'
import resourceTab from '../../images/resource-tab.svg'

// need to import PUBLIC_URL to use this : <img src={`${PUBLIC_URL}${resourceTab}`} alt="bokmärke" /> 
const DocHit = ({item, name, height, charcount, pinned, logOutbound}) => {
  const { PUBLIC_URL } = useContext(SearchContext)
  // om det inte finns någon site[item._source.site] så kraschar det. Returnera null istället för att undvika sökkrasch när nya sajter håller på att byggas upp.
  if (!sites[item._source.site]) return null
  const url = `https://${sites[item._source.site].url}${item._source.url}`
  const maxchars = !!charcount ? charcount : 120
  const desc = item._source.description.split('').length < maxchars ? item._source.description : item._source.description.slice(0,maxchars) + '…'
  return  (
      <div onClick={(evt)=>logOutbound(url,evt)} className={`${pinned ? 'pinned pinned-hit' : 'doc-hit'}`}>
        {pinned && <img src={`${PUBLIC_URL}${resourceTab}`} alt="bokmärke" className="pin"/> }
          <style jsx>{`
          .doc-hit, .pinned-hit {
            clear: both;
            width: 100%;
            position: relative;
            margin: 15px;
            margin-left: 0;
            font-size: 1.1em;
            height: ${height}px;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            border-bottom: dashed 1px rgba(0,0,0,0.1);

            &.pinned {
              font-size: 120%;
              cursor: pointer;
              position: relative;
              overflow:visible;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              height: 110px !important;
              border-radius: 4px;
              border-bottom: none !important;
              /*background-image: url(${`${getImageUrl(item._source.site, item._source.doctype,item._source.thumbnail)}`});*/
              background-size: contain;
              background-position: right center;
              background-repeat: no-repeat;

              .img{
                display:inline-block !important;
                float: right;
                height: 100%;
                margin: 0;
                width: 30%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                @media (max-width: 767px) {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }

                @media (max-width: 480px) {
                  display:none;
                }
              }

              @media (max-width: 480px) {
                width: calc(100% - 5px);
                min-height: 50px;
                height: auto !important;
              }

              .pin {
                position: absolute;
                top: 5px;
                left: -7px;
                width: 20px;
              }

              .doc-title {
                padding: 1rem;
                font-weight: 700;
                font-size: 20px;
                text-decoration: none;
                display: block;
                text-shadow: 0.8px 0.8px 0.8px #fff;

              }
              p.description {
                margin: 1rem;
                width: 50%;
                position: absolute;
                bottom: 0px;
                display: -webit-box;

                @media (max-width: 480px) {
                  display:none;
                }
              }
            }
            .pinned-hit {
              border-bottom: none !important;
            }
            .img {
              display: none;
              background-image: url(${`${getImageUrl(item._source.site, item._source.doctype,item._source.thumbnail)}`}) !important;
              background-color: #eee;
              background-size: cover;
              background-position: center top;
              width: ${pinned ? '160px' : 'calc(100% - 10px)'}; 
              height: ${height}px;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;

              @media (min-width: 768px) {
                display: inline-block;
                background-image: url(${`${getImageUrl(item._source.site, item._source.doctype, item._source.thumbnail)}`}) !important;
                background-color: #eee;
                background-size: cover;
                background-position: center center;
                height: ${height}px;
                float: left;
                margin: 10px;
                margin-top: 0;
                border: 1px solid #ccc;
                border-radius: 4px;            
                width: ${pinned ? '160' : '120'}px;
              }
            }
          .description {
            //display: none;
            font-size: 0.8em;
            color: #888;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;

            @media(min-width: 768px) {
              color: #888;
              line-height: 1.2;
              font-size: 0.9em;
              white-space: normal;
              overflow: unset;
              text-overflow: unset;

              &.truncate-overflow {
                margin:0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                box-orient: vertical;
                max-height: calc(${height}px + 0.5em);
                overflow: hidden;
              }
            }
          }

          @media (min-width: 768px) {
              border-bottom: none !important;
            }
        }
      `}</style>
      <a href={url} onClick={(evt)=>logOutbound(url,evt)}><div className="img"></div></a>
      <a href={url} onClick={(evt)=>logOutbound(url,evt)} className="doc-title">{item._source.post_title}</a>
      <p className="description truncate-overflow">{desc}</p>
      </div>
    )
  } 
  const sites = {
    // TODO: hexa - behöver lösas i indexeringen
    '': {url:'moderskeppet.se',img:'//moderskeppet.se'},
    'ms': {url:'moderskeppet.se',img:'//moderskeppet.se'},
    'www': {url:'moderskeppet.se',img:'//moderskeppet.se'},
    'some': {url:'some.moderskeppet.se',img:''},
    'm365': {url: 'm365.moderskeppet.se',img:''},
    'foto': {url: 'foto.moderskeppet.se',img:''},
    'video': {url: 'video.moderskeppet.se',img:''},
    'design': {url:'design.moderskeppet.se',img:''},
    'verktyg': {url:'verktyg.moderskeppet.se',img:''}
  }

  const getImageUrl = (site, type, thumb_url) => {
    const placeHolder = 'https://assets.moderskeppet.se/bilder/bild-saknas.png';
    const url = thumb_url.indexOf('://') === -1 ? `${sites[site].img}${thumb_url}` : thumb_url;
    return url === '' || url === '//moderskeppet.se' ? placeHolder : url;
  }



  export default DocHit
import React, { useContext } from "react"
import SearchBar from './SearchBar'
import SearchResults from './SearchResults'
import { SearchContext } from '../../Providers/SearchProvider'
function Search ({mode}) {
  const { query,
    // sites,
    // setSites,
    hasError,
  } = useContext(SearchContext)
  return (
    <div className="search">
      <style global jsx>{`
        .search {
          font-family: myriad-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
          .search-form-init {
            border-radius: 12px;

            & + .input-group-addon{
              border-bottom-right-radius: 12px;
              border-top-right-radius: 12px;
            }
          }
          .version-info {
            color: white;
          }
        }
        div.tajt.well {
              margin: 0 !important;
              padding: 0 !important;
              margin: auto !important;
          }
        .input-group-addon {
          border: none;
          border-bottom-right-radius: 0;

          &::last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 12px;
          }
        }
      `}</style>
      <SearchBar debug={mode==='standalone'} />
      {(query.query !== '') && !hasError && <SearchResults debug={mode==='standalone'} />}
    </div>
  )
}
export default Search
import axios from "axios";
const CancelToken = axios.CancelToken;

let cancel;

function fetchReducer(state, action) {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case "FETCH_SUCCESS":
      // console.log('action payload', action.payload)
      return {
        ...state,
        isLoading: false,
        hasError: false,
        course_hits: !!action.payload.course ? action.payload.course.by_top_hit.hits.hits : [],
        course_count: !!action.payload.course ? action.payload.course.by_top_hit.hits.total.value : 0,
        document_hits: !!action.payload.doc ? action.payload.doc.by_top_hit.hits.hits : [],
        document_count: !!action.payload.doc ? action.payload.doc.by_top_hit.hits.total.value : 0,
        movie_hits: !!action.payload.movie ? action.payload.movie.by_top_hit.hits.hits : [],
        movie_count: !!action.payload.movie ? action.payload.movie.by_top_hit.hits.total.value : 0,
        pinned: !!action.payload.pinned ? action.payload.pinned.by_top_hit.hits.hits.filter(p => {
          return p._score > 200
        }).slice(0, 2) : [],
        q: action.payload.q,
      }
    case "CLEAR":
      return {
        ...state,
        isLoading: false,
        hasError: false,
        course_hits: [],
        course_count: 0,
        document_hits: [],
        document_count: 0,
        movie_hits: [],
        movie_count: 0,
        pinned: [],
        q: [],
        page: 1,
        mode: 'default'
      }

    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case "FETCH_TYPING":
      return {
        ...state,
        isLoading: true,
        hasError: false
      }
    case "SET_MODE":
        return {
          ...state,
          mode: action.mode
        }
    case "SET_PAGE":
        return {
          ...state,
          page: action.page
        }
    case "PING":
        return {
          ...state,
          isAlive: action.payload
        }
    default:
      throw new Error()
  }
}

async function  ping (API_URL, dispatch) {
  const res = await axios.get(`${API_URL}/ms/v2/search/ping/pong`)
  dispatch({type:"PING", payload: res.data})
}

// async function log (dir,q,info) {
//   const api = 'ms/v2/search-log/'+dir+'/'
//   const query = dir === 'in' ? '?c=' + info : '?id=' + info
//   const url = this.root + api + q + query 
//   return this.get(url,true)
// }

async function api(url, mode='GET') {
  return await axios.get(`${url}` , {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}

async function fetchHits(query, mode, page, sites, API_URL, dispatch) {
  if(query.query==='') {
    dispatch({ type:'CLEAR'})
    return
  }
  dispatch({ type: "FETCH_START" })
  if (cancel !== undefined) {
    cancel('typing')
  }
  try {
    const pp = mode === 'default' ? 5 : 10
    const res = await api(`${API_URL}/ms/v2/search/${mode}/${query.query}?p=${page}&pp=${pp}&si=${sites}`)
    let data = res.data
    dispatch({ type: "FETCH_SUCCESS", payload: data })
  } catch (err) {
    if(err.message === 'typing') {
      dispatch({ type: "FETCH_TYPING"})
    } else {
      dispatch({ type: "FETCH_FAILURE" })
    }
  }
}

export { fetchReducer, fetchHits, ping }

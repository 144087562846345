import React, {useContext } from "react"
import { SearchContext } from '../../Providers/SearchProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faWindowClose } from '@fortawesome/pro-solid-svg-icons'

export default ({debug}) => {
  const { 
    query,
    setQuery,
    reset,
    totalHits,
    hasError, 
  } = useContext(SearchContext)
  const noSearch = totalHits === 0 && !hasError && query.query.length === 0
  const noHits = totalHits === 0 && !hasError && query.query.length > 0
  return (
      <div className="searchbar">
      <style jsx>{`
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display:none;
      }

      input[type="search"] {
        font-size: ${debug ? '1.5em' : ''};
      }

      `}</style>
        <form method="get" action=".">
          <div className="input-group">
            <input type="search"
              name="qs"
              placeholder="Sök ämnen, kurser, avsnitt och sidor" 
              className={`form-control${ noSearch ? ' search-form-init': '' }`}
              id="search"
              value={query.query}
              onChange={event => setQuery({query:event.target.value,from:'typing'})}
              // onKeyDown={event => {if(event.key ==='Enter') event.preventDefault()}}
            />
            <span className="input-group-addon">
              {(noSearch) && <FontAwesomeIcon icon={faSearch} size="1x"/>}
              {(totalHits > 0 || noHits) &&<FontAwesomeIcon onClick={reset} icon={faWindowClose} size="1x"/>}
            </span>
          </div>
        </form>
      {hasError && <div className="alert alert-danger"><small>Någonting gick fel!</small></div>}
  </div>
  )
}
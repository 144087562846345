import React from "react"

export default ({ count, page, dispatch}) => {
    const perPage = 10
    const firstPageOffset = 5
    const maxPage = Math.ceil( (count-firstPageOffset)/perPage )

    if(page > maxPage) {
        dispatch({type:'SET_PAGE', page:(maxPage)})
        page = maxPage
    }

    return (<div className="pagenav">
        <style jsx>{`
        .pagenav {
            text-align: center;
        }
        button {
            background: none!important;
            border: none;
            padding: 0!important;
            /*optional*/
            font-family: arial, sans-serif;
            /*input has OS specific font-family*/
            color: #069;
            text-decoration: underline;
            cursor: pointer;
          }
          .pageinfo {
              margin: 10px 30px;
          }
        `}</style>
        {(page > 1) && <button onClick={()=>dispatch({type:'SET_PAGE', page:(page - 1)})}>Föregående</button>}
        {(page === 1) && <button onClick={()=>dispatch({type:'SET_MODE', mode:'default'})}>Återgå till översikten</button>}
        <span className="pageinfo">Sida {page} av {maxPage}</span>
        {(page < maxPage) && <button onClick={()=>dispatch({type:'SET_PAGE', page:(page + 1)})}>Nästa</button>}
    </div>)
}
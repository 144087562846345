import React from "react"

import PageNav from './PageNavigator'

export default ({caption, count, page, name, mode, dispatch}) => {
    return (
        <div className="doctype-header"><style jsx>{`
        .doctype-header {
            margin: 20px 15px;
            border-bottom: 1px solid #ccc;
            h2 {
                margin:0;
                padding:0;
                font-size: 1.2em;  
            }
        }
        `}</style>
        <h2>{caption}</h2> 
            {mode===name  && <PageNav count={count} page={page} dispatch={dispatch} />}
        </div>
    )
}
import React, { createContext, useState, useReducer, useEffect } from 'react'
import { fetchHits, fetchReducer, ping } from '../Utils/SearchAPI'
import useSearchState from '../Utils/useSearchState'
import useDebounce from '../Hooks/useDebounce'
export const SearchContext = createContext()

window.onpopstate = (e) => {
    console.log(JSON.stringify(e))
}

const changeHistory = (query, mode, page, searchpref) => {
    const qt = mode !== 'default' ? `&qt=${mode}` : ''
    const qp = mode !== 'default' && page > 0 ? `&qp=${page}` : ''
    const sp = searchpref !== 'membership_content' ? `&sp=${searchpref}` : ''
    const q = query.query !== '' ? `qs=${query.query}` : ''
    const params = `${q}${qt}${qp}${sp}`
    if (params !== '') {
        window.history.pushState(null, null, `?${params}`)
    }
}

export const SearchProvider = ({ children, sites: defaultSites, API_URL, loggedin, premium, site_name, active_membership }) => {
    const [totalHits, setTotalHits] = useState(0)
    const PUBLIC_URL = API_URL.includes('lvh.me') ? 'https://search.moderskeppet.lvh.me' : 'https://search.moderskeppet.se'
    const [sites, setSites, searchPref, setSearchPref, query, setQuery] = useSearchState(defaultSites)

    const [{ course_hits, course_count, document_hits, document_count, movie_hits, movie_count, pinned, q, hasError, isLoading, mode, page, isAlive }, dispatch] =
        useReducer(fetchReducer, {
            course_hits: [],
            course_count: 0,
            document_hits: [],
            document_count: 0,
            movie_hits: [],
            movie_count: 0,
            pinned: [],
            q: [],
            isLoading: true,
            hasError: false,
            isAlive: true,
            mode: 'default',
            page: 1
        })

    const getParamsFromQueryString = ({ from }) => {
        const queryParams = new URLSearchParams(window.location.search)
        const qs = queryParams.get('qs')
        let qt = queryParams.get('qt')
        let qp = queryParams.get('qp')
        let sp = queryParams.get('sp')

        if (qs !== null && qs !== 'undefined') {
            // the in class forces a scrollbar thet doesn't seem to be needed anymore
            const navsearch = document.getElementById('navsearch')
            if (navsearch && 'classList' in navsearch) navsearch.classList.remove('in')

            if (sp !== null) setSearchPref(sp)

            setQuery({ query: qs, from: from })
            if (qt === null) qt = 'default'
            dispatch({ type: 'SET_MODE', mode: qt })
            if (qp === null) qp = 1
            dispatch({ type: 'SET_PAGE', page: isNaN(qp) ? 1 : 1 * qp })
        } else {
            reset()
        }
    }

    // check if searchPrefs changes
    useEffect(() => {
        if (searchPref === 'membership_content') setSites(defaultSites)
        else setSites('')
    }, [searchPref, defaultSites]) // eslint-disable-line react-hooks/exhaustive-deps

    // check url params on load
    useEffect(() => {
        getParamsFromQueryString({ from: 'query' })
        // Also check ping
        ping(API_URL, dispatch)
    }, [API_URL]) // eslint-disable-line react-hooks/exhaustive-deps

    // Handle back in browser
    useEffect(() => {
        const popstateHandler = () => { // removed param evt for linting purposes
            getParamsFromQueryString({ from: 'popstate' })
        }
        window.addEventListener('popstate', popstateHandler)
        return () => {
            window.removeEventListener('popstate', popstateHandler)
        }
    })

    useEffect(() => {
        if (query.query === '' || query.query === 'undefined') return
        if (mode === undefined) return
        if (page === undefined) return

        // reset results if typing
        if (query.from === 'typing') {
            dispatch({ type: 'SET_MODE', mode: 'default' })
            dispatch({ type: 'SET_PAGE', page: 1 })
        }
        if (query.from !== 'popstate' && query.from !== 'typing') {
            changeHistory(query, mode, page, searchPref)
        }

        // get new data on navigation
        // start search again when done typing
        if (query.from !== 'doneTyping') {
            fetchHits(query, mode, page, sites, API_URL, dispatch)
        }
    }, [query, mode, page, API_URL, sites, searchPref])

    const debouncedSearchTerm = useDebounce(query, 1000)

    useEffect(() => {
        // Kolla om query.from är keyboard
        // om så är fallet - sätt till doneTyping och reset till start
        if (query.from === 'typing') {
            setQuery({ query: query.query, from: 'doneTyping' })
        }
        //fetchHits(debouncedSearchTerm, mode, page, dispatch)
        // Post LOG IN
    }, [debouncedSearchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTotalHits(document_count + movie_count + course_count)
    }, [document_count, movie_count, course_count])

    const reset = () => {
        changeHistory({ query: '', from: 'reset' }, 'default', 1, 'membership_content')
        dispatch({ type: 'CLEAR' })
        setQuery({ query: '', from: 'reset' })
        dispatch({ type: 'SET_MODE', mode: 'default' })
        dispatch({ type: 'SET_PAGE', page: 1 })
    }

    return (
        isAlive && (
            <SearchContext.Provider
                value={{
                    query,
                    setQuery,
                    site_name,
                    sites,
                    setSites,
                    defaultSites,
                    searchPref,
                    setSearchPref,
                    loggedin,
                    premium,
                    active_membership,
                    totalHits,
                    course_hits,
                    course_count,
                    document_hits,
                    document_count,
                    movie_hits,
                    movie_count,
                    pinned,
                    q,
                    hasError,
                    isLoading,
                    mode,
                    page,
                    dispatch,
                    reset,
                    PUBLIC_URL
                    // version
                }}
            >
                {children}
            </SearchContext.Provider>
        )
    )
}

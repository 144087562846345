import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

/**
 * 
 * @param {color} string: color of the icon. Default is 'white' (green, m365, etc. See Assets: variables-colors.less for valid options)
 * @param {bgColor} string: background color of the option. Default is '#fff'
 * @param {selected} boolean: is the option selected?
 * @param {description} string: description of the option
 * @param {value} string: value of the option
 * @param {onChange} function: function to call when the option is selected
 */

function RadioOption({selected, description, value, onChange, color, bgColor}) {
  return (
    <div className="radio-option" onClick={()=>{onChange(value)}}>
      <style jsx global>{`
        .bg-color {
          background-color: ${bgColor ?? '#fff'};
          border-radius: 50%;
          outline: solid 1px ${bgColor ?? '#fff'};
        }
        `}</style>
        <FontAwesomeIcon icon={selected ? faCheckCircle : faCircle} className={`color-${selected ? color : 'white'}${selected ? ' bg-color' : '' }`}/>
        <input
          className="radio-option-input hide-radio"
          name='search-preference'
          id={value}
          type="radio"
          value={value}
          onChange={()=>{onChange(value)}}
          checked={selected}
        />
      <label htmlFor={value} className="radio-option-description">{description}</label>
    </div>
  )    
}

export default RadioOption

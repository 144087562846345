import React from "react"

const CourseHit = ({ item, name, height, charcount, logOutbound }) => {

  // TODO - passa in detta från settings tänker jag
  // const baseurl = `${sites[item._source.site]}`
  // const url = baseurl !== 'undefined' ? `https://${baseurl}${item._source.url}` : `${item._source.url}`
  const url = `https://play.moderskeppet.se${item._source.url}`
  const maxchars = !!charcount ? charcount : 120
  const desc = item._source.description.split('').length < maxchars ? item._source.description : item._source.description.slice(0, maxchars) + '…'
  let posterWidth = 0.72 * height

  return (
    <div onClick={(evt) => logOutbound(url, evt)} className={`course-hit`}>
      <style jsx>{`
            .course-hit {
              width: 100%;
              position: relative;
              margin: 15px;
              margin-left: 0;
              font-size: 1.1em;
              height: ${height}px;
              overflow: hidden;
              border-bottom: none !important;
              cursor: pointer;
          }
          .img {
                background-image: url(${`${getImageUrl(item._source.site, item._source.doctype, item._source.thumbnail)}`}) !important;
                background-size: cover;
                background-position: center center;
                width: 120px;
                height: ${height}px;
                float: left;
                background-color: #eee;
                margin: 10px;
                margin-top: 0;
                border: 1px solid #ccc;
                border-radius: 4px;                     
            }
            .poster {
              object-position:left top;
              width: ${posterWidth}px;
              max-height: ${height}px;
              border-radius: 4px;

            }
            .description {
              color: #888;
              font-size: 0.9em;
            }
        `}</style>
      <div className={`img ${name==='course' ? 'poster' : ''}`}></div>
      <a href={url}>{item._source.post_title}</a>
      <div className="description">{desc}</div>
    </div>
  )
}
// const sites = {
//   '': 'moderskeppet.se',
//   'ms': 'moderskeppet.se',
//   'some': 'moderskeppet.se'
// }

const getImageUrl = (site, type, thumb_url) => {
  if (thumb_url.includes('http'))
    return thumb_url

  return `https://moderskeppet.se/${thumb_url}`
}

export default CourseHit
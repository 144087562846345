import React from "react"
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ShowSubPageNav = ({name,caption, count, setDetail}) => {
    return (
        <>
        <style jsx>{`
            li {
                list-style: none;
                align-self: center;
              &.show-subpage {
                display: flex;
                height:100%;
                width:100%;
                padding:11px;
                justify-content: center;
              }
            }
        button {
                font-family: "Verdana" sans-serif;
                font-size: 1em;
                text-align: left;
                color: blue;
                background: none;
                margin: 0;
                padding: 0;
                border: none;
                cursor: pointer;
                border-bottom: 1px solid;
              &.all-courses-btn {
                /*height:163px;*/
                width:100%;
                display:inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration:none;
                color:white;
                background-color: #667980;
                padding:1rem;
                margin: 0;
                border-radius: 4px;
              }
              }
            @media only screen and (min-width:769px) {
              li {
                &.show-subpage {
                padding:0;
                svg{
                    display:none;
                  }
                }
              }
                .all-courses-btn {
                display:flex;
                justify-content: center;
                align-items: center;
                text-decoration:none;
                color:white;
                background-color: #667980;
                padding:1rem;
                margin: 0;
                border-radius: 4px;
              }
              li {
                align-self: stretch;
            }
            }
              `}</style>

          <li className="show-subpage">
            <button onClick={()=>setDetail(name)} className={name === 'course' ? "all-courses-btn" : ''}>
            {name==='course' && <FontAwesomeIcon size="3x" icon={faChevronRight} /> }
            {name!=='course' && <> Bläddra bland {count-5} {caption.toLowerCase()}</> }
            </button>
          </li>
        
        </>
        )
}
 export default ShowSubPageNav;